// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCc07ASJ7ah5BR5aQzOxDJMJXOTXQubL24",
  authDomain: "tshaller-artguesser.firebaseapp.com",
  projectId: "tshaller-artguesser",
  storageBucket: "tshaller-artguesser.appspot.com",
  messagingSenderId: "268397098100",
  appId: "1:268397098100:web:33df2920bbb832dfd00d21",
  measurementId: "G-HWYBS9D9EL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);